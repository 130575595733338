import { useEffect, useState } from 'react';
import moment from 'moment';
import { useInterval } from './useInterval';

const useAutoUpdateTime = (date) => {
    const [fromNow, setFromNow] = useState('');

    useEffect(() => {
        if (date)
            setFromNow(moment(date).fromNow());
    }, [date]);

    useInterval(() => {
        const now = moment();
        if (now.diff(date, 'days') < 1) {
            setFromNow(moment(date).fromNow());
        }
    }, 60000);

    return fromNow;
};

export default useAutoUpdateTime;