import React, { useMemo, useCallback, memo, useEffect, useRef } from 'react'
import ListOfProducts from '../components/product/productsList'
import ProductCard from '../components/product/productCard'
import {
    CircularProgress,
    Grid,
    Typography
} from '@material-ui/core'
import useMainContext from '../hooks/useMainContext'
import ProductListGrouped from '../components/product/productListGrouped'
import SearchInput from '../components/searchInput'

export const ListOfProductsWithData = memo(function ListOfProductsWithData({ publicData, setSelected = () => { } }) {
    const {
        productsFiltered,
        cart,
        addToCart,
        setCart,
        productGroups,
        config,
        search
    } = useMainContext();

    // Ref for the main container
    const mainContainerRef = useRef(null);

    // Scroll to top when search changes
    useEffect(() => {
        // Scroll top for main container
        if (mainContainerRef.current) {
            mainContainerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [search]);

    const getProductsGrouped = useCallback(() => {
        if (!config?.productListGrouped || !productGroups?.length) {
            return [];
        }

        const today = new Date().getDay();

        // Create a map of all active product IDs for efficient lookup
        const activeProductMap = new Map(
            productsFiltered
                .filter(product => product.active)
                .map(product => [product.id, product])
        );

        // Modify group mapping to use the activeProductMap
        const groupedProducts = productGroups.map(group => {
            // Find actual product objects for the group
            const groupProducts = group.products
                ? group.products
                    .map(productId => activeProductMap.get(productId))
                    .filter(product => product !== undefined)
                : [];

            return {
                isProductGroup: true,
                ...group,
                products: groupProducts
            };
        }).sort((a, b) => a.sort - b.sort);

        // Find truly ungrouped products
        const ungroupedProducts = productsFiltered.filter(product =>
            product.active &&
            !groupedProducts.some(group =>
                group.products.some(p => p.id === product.id)
            )
        );

        // Add ungrouped products if exists
        if (ungroupedProducts.length) {
            groupedProducts.push({
                isProductGroup: false,
                name: "Otras opciones",
                nameVisible: true,
                products: ungroupedProducts,
                activeDays: [0, 1, 2, 3, 4, 5, 6],
            });
        }

        // Final filtering of active groups
        return groupedProducts.filter(group =>
            group.products.length > 0 &&
            group.activeDays?.includes(today)
        );
    }, [config, productGroups, productsFiltered]);

    // Memoize grouped products
    const productsGrouped = useMemo(getProductsGrouped, [getProductsGrouped]);

    // Memoize createSecondary function
    const createSecondary = useCallback((prod) => prod.ingredients, []);

    // Render loading state for public data
    if (publicData && productsFiltered.length === 0) {
        return (
            <Grid container direction="column" justify="center" alignItems="center" style={{ height: '70vh' }}>
                <Grid item>
                    <CircularProgress style={{ color: '#000000' }} />
                </Grid>
            </Grid>
        );
    }

    // Render for public data
    if (publicData) {
        return (
            <Grid
                ref={mainContainerRef}
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
            >
                {productsGrouped?.length <= 1
                    ? productsFiltered
                        .filter(prod => prod.active)
                        .map(prod => (
                            <Grid item key={prod.id}>
                                <ProductCard
                                    key={prod.id}
                                    product={prod}
                                    cart={cart}
                                    setCart={setCart}
                                    addToCart={addToCart}
                                    width={'100%'}
                                />
                            </Grid>
                        ))
                    : <ProductListGrouped productsGrouped={productsGrouped} />
                }
                {search && productsFiltered.length === 0 && (
                    <Grid item>
                        <Typography variant='h6'>No se encontró {search} 😔</Typography>
                    </Grid>
                )}
            </Grid>
        );
    }

    // Render for non-public data (restored original behavior)
    return (
        <Grid ref={mainContainerRef} container>
            <Grid item xs={12} style={{ padding: 10 }}>
                <SearchInput show={true} />
            </Grid>
            <Grid item xs={12}>
                {productsFiltered.length === 0 ? (
                    <p>No hay productos para mostrar</p>
                ) : (
                    <ListOfProducts
                        items={productsFiltered}
                        createSecondary={createSecondary}
                        setSelected={setSelected}
                    />
                )}
            </Grid>
        </Grid>
    );
});

export default ListOfProductsWithData;