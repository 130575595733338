import { useMemo } from 'react';

// Crear un índice de búsqueda para mejora de rendimiento
function createSearchIndex(products) {
    return products.map(product => ({
        id: product.id,
        searchText: [
            product.name.toLowerCase(),
            ...(product.keywords || []).map(k => k.toLowerCase())
        ].join(' ')
    }));
}

// Implementación de búsqueda con índice
function useOptimizedProductSearch(products, search) {
    const searchIndex = useMemo(() => createSearchIndex(products), [products]);

    const filteredProducts = useMemo(() => {
        if (!search || search.length === 0) return products;

        const searchLower = search.toLowerCase();

        // Usar búsqueda con índice para mejor rendimiento
        const matchedIds = searchIndex
            .filter(item => item.searchText.includes(searchLower))
            .map(item => item.id);

        return products.filter(product => matchedIds.includes(product.id));
    }, [search, products, searchIndex]);

    return filteredProducts;
}

export default useOptimizedProductSearch;