import { useEffect, useRef } from "react"


export function useInterval(callback = () => { }, delay) {
    const savedCallback = useRef()

    useEffect(() => {
        savedCallback.current = callback;
    }, [])

    useEffect(() => {
        function tick() {
            savedCallback.current()
        }
        if (delay !== null && delay > 0) {
            let id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])
}