import React, { Fragment } from 'react'
import {
    Grid,
    Typography,
    CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import OrderViewCard from '../components/order/orderView/card'
import Divider from '../components/divider'
import useMainContext from '../hooks/useMainContext'
import HorizontalScroll from '../components/horizontalScroll'
import useOrderContext from '../hooks/useOrderContext'

export const useStyles = makeStyles((theme) => ({
    horizontalScroll: {
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 0',
        '&::-webkit-scrollbar': {
            height: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E24620',
            borderRadius: 10,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#F7F9F9',
            borderRadius: 10,
        },
    },
    sectionTitle: {
        color: '#333',
        fontStyle: 'italic',
        fontSize: 50,
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
    }
}))

export const MyOrdersWithData = ({ type = 'section' }) => {
    const classes = useStyles()
    const { config, search } = useMainContext()
    const { myActiveOrders, myLastOrders, isLoading } = useOrderContext()




    // Conditional rendering logic
    if (myActiveOrders.length === 0 && myLastOrders.length === 0)
        return null

    if (type === 'section' && search)
        return null

    if (isLoading)
        return (
            <Grid container direction="column" justify="center" alignItems="center" style={{ height: '70vh' }}>
                <Grid item>
                    <CircularProgress style={{ color: '#EC441B' }} />
                </Grid>
            </Grid>
        );

    return (
        <>
            {myActiveOrders.length > 0 && (
                <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} style={{ padding: '0 20px' }}>
                        <Typography variant="h6" className={classes.sectionTitle} align='center' fullWidth>
                            {`Pedidos en proceso (${myActiveOrders.length})`}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2} justify="center" alignItems="center" style={{ padding: 10 }}>
                        {myActiveOrders.map(order => (
                            <OrderViewCard
                                key={order.id}
                                order={order}
                                config={config}
                            />
                        ))}
                    </Grid>
                </Grid>
            )}

            {myLastOrders.length > 0 && (
                <Grid container spacing={0} direction="row" justify="center" alignItems="center" style={{ padding: '0 12vw 20px 12vw' }}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.sectionTitle} align='center' fullWidth>
                            {type === 'page' ? 'Últimos pedidos' : 'Volver a pedir'}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2} justify="center" alignItems="center" style={{ paddingTop: 10 }}>
                        <HorizontalScroll>
                            {myLastOrders.map(order => (
                                <OrderViewCard
                                    key={order.id}
                                    order={order}
                                    config={config}
                                    mode='history'
                                />
                            ))}
                        </HorizontalScroll>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default MyOrdersWithData