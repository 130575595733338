import React, { useMemo, useCallback, memo } from 'react'
import {
    Grid,
    Card,
    CardContent,
    Typography,
    IconButton,
    CardMedia,
    CardActionArea
} from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Slider from "react-slick"
import ProductCard from '../productCard'
import useMainContext from '../../../hooks/useMainContext'

// Memoize arrow components to prevent unnecessary re-renders
const SampleNextArrow = memo(function SampleNextArrow(props) {
    const { style, onClick } = props
    return (
        <div
            style={{
                ...style,
                zIndex: 1000,
                right: -25,
                float: 'right',
                top: '50%',
                position: 'absolute',
                height: '100%'
            }}
            onClick={onClick}
        >
            <IconButton>
                <NavigateNextIcon style={{ display: 'block' }} />
            </IconButton>
        </div>
    )
})

const SamplePrevArrow = memo(function SamplePrevArrow(props) {
    const { style, onClick } = props
    return (
        <div
            style={{
                ...style,
                zIndex: 1000,
                left: -25,
                float: 'left',
                top: '50%',
                position: 'absolute',
                height: '100%'
            }}
            onClick={onClick}
        >
            <IconButton>
                <NavigateBeforeIcon style={{ display: 'block' }} />
            </IconButton>
        </div>
    )
})

// Memoized main component
const GroupCarousel = memo(function GroupCarousel({ group }) {
    const { cart, addToCart, setCart } = useMainContext();

    // Memoize products to prevent unnecessary re-computations
    const products = useMemo(() =>
        group?.products?.length > 0 ? group.products : [],
        [group?.products]
    );

    // Custom dots function
    const customDots = useCallback((dots) => {
        try {
            const maxDotsToShow = 5;
            const numericalDots = Object.keys(dots)
                .filter(key => !isNaN(parseInt(key)))
                .map(key => dots[key]);

            const currentSlide = numericalDots.findIndex(dot =>
                dot.props?.className?.includes('slick-active')
            );

            // Handle few dots scenario
            if (numericalDots.length <= maxDotsToShow) {
                return (
                    <ul
                        className={dots.className}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 0,
                            padding: 0
                        }}
                    >
                        {numericalDots}
                    </ul>
                );
            }

            // Sliding dots logic
            const halfMax = Math.floor(maxDotsToShow / 2);
            let start = Math.max(0, currentSlide - halfMax);
            let end = Math.min(start + maxDotsToShow, numericalDots.length);

            const visibleDots = numericalDots.slice(start, end);

            return (
                <ul
                    className={dots.className}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 0,
                        padding: 0
                    }}
                >
                    {start > 0 && <li style={{ margin: '0 8px' }}>...</li>}
                    {visibleDots}
                    {end < numericalDots.length && <li style={{ margin: '0 8px' }}>...</li>}
                </ul>
            );
        } catch (e) {
            console.error('Error in CustomDots', e);
            return (
                <ul className={dots.className}>
                    {Object.keys(dots)
                        .filter(key => !isNaN(parseInt(key)))
                        .map(key => dots[key])}
                </ul>
            );
        }
    }, []);

    // Memoize slider settings
    const settings = useMemo(() => ({
        dots: true,
        infinite: products.length > 1,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        appendDots: customDots,
        responsive: [
            {
                breakpoint: 2048,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: products.length < 4 ? 1 : 4,
                    infinite: products.length >= 4
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }), [products.length, customDots]);

    // Early return if no products
    if (products.length === 0) {
        return null;
    }

    return (
        <Card style={{ maxWidth: '95vw' }}>
            <CardActionArea>
                <div style={{ position: 'relative', minHeight: 120 }}>
                    {group?.img && (
                        <CardMedia
                            image={group.img}
                            title={group?.name || 'imagen'}
                            style={{ height: 200 }}
                        />
                    )}

                    {group?.nameVisible && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                width: '100%',
                                transform: 'translateY(-50%)',
                                backgroundColor: 'rgba(255, 255, 255, 0.708)',
                                padding: '8px',
                                borderRadius: '4px',
                            }}
                        >
                            <Typography
                                variant="h5"
                                style={{
                                    textAlign: 'center',
                                    fontSize: 50,
                                    fontStyle: 'italic',
                                    color: '#333'
                                }}
                            >
                                {group?.name || 'Título'}
                            </Typography>
                        </div>
                    )}
                </div>
            </CardActionArea>
            <CardContent>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: '100%' }}
                >
                    <Grid item xs={12}>
                        <Slider {...settings}>
                            {products.map((prod) => (
                                <ProductCard
                                    key={prod.id}
                                    product={prod}
                                    cart={cart}
                                    setCart={setCart}
                                    addToCart={addToCart}
                                    width={'100%'}
                                    zoomOnHover={false}
                                />
                            ))}
                        </Slider>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
});

export default GroupCarousel;